@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  .page-two {
    margin-left: auto;
  }
}

@page {
  size: auto;
  margin: .5in .5in .5in .5in !important;
}